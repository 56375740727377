import { Popover } from '@systemeio/ui-shared'
import { MailingSettingsEditInterface } from 'modules/profile/mailing-settings/types/mailing-settings-interface'
import React, { useState } from 'react'
import FormInput from 'shared/components/form-input'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import { LanguageSwitcherMenuBase } from 'shared/components/language-switcher/language-switcher-menu-base'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'

interface AgreementEditorProps {
  tempState: MailingSettingsEditInterface
  setTempState: React.Dispatch<React.SetStateAction<MailingSettingsEditInterface>>
  locale: LocaleEnum
  errors: Record<LocaleEnum, string>
}

function UnsubscribeLink({ setTempState, tempState, locale, errors }: AgreementEditorProps) {
  const { t } = useLocoTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(locale)

  return (
    <div>
      <FormInput
        label={
          <>
            {t('dashboard.settings.mailing.unsubscribe_link_title')}
            <Popover
              label={t('dashboard.settings.mailing.unsubscribe_link_popover_title')}
              popoverClassName={'w-[200px] sm:w-[350px]'}
            >
              {show => (
                <QuestionMarkIcon
                  className={`${
                    show ? 'fill-blue' : 'fill-gray-100'
                  } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                />
              )}
            </Popover>
          </>
        }
        labelClassName={'flex items-center gap-2'}
        labelRightElement={
          <LanguageSwitcherMenuBase
            changeLanguage={language => {
              setSelectedLanguage(language)
            }}
            selectedLanguage={selectedLanguage}
          />
        }
        htmlFor={'none'}
        error={errors[selectedLanguage]}
        value={tempState.unsubscriptionLinkText[selectedLanguage] || ''}
        onChange={e =>
          setTempState(prev => ({
            ...prev,
            unsubscriptionLinkText: {
              ...prev.unsubscriptionLinkText,
              [selectedLanguage]: e.target.value,
            },
          }))
        }
      />
      <FieldErrorAndDescription error={errors[selectedLanguage]} />
    </div>
  )
}

export default UnsubscribeLink
