import { Modal } from '@systemeio/ui-shared'
import EmailTemplateSelectorNewEditor from 'modules/email/components/email-template-selector/email-template-selector-new-editor'
import { useGetEmailTemplates } from 'modules/email/hooks/use-get-email-templates'
import { EmailTemplateItemInterface } from 'modules/email/types/email-template-interface'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface EmailTemplateSelectorNewEditorModalProps {
  opened: boolean
  onChoose: (template: EmailTemplateItemInterface) => Promise<void>
  onClose: () => void
}

const EmailTemplateSelectorNewEditorModal = ({
  opened,
  onChoose,
  onClose,
}: EmailTemplateSelectorNewEditorModalProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const { t } = useLocoTranslation()
  const [chosenTemplate, setChosenTemplateId] = useState<EmailTemplateItemInterface | null>(null)

  const { templates } = useGetEmailTemplates()

  useEffect(() => {
    if (templates) {
      setChosenTemplateId(templates[0])
    }
  }, [templates])

  const submit = async () => {
    if (!chosenTemplate) return
    setIsFetching(true)
    try {
      await onChoose(chosenTemplate)
    } catch {
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('dashboard.email_template_selector.choose_template_label')}
      isFetching={isFetching}
    >
      <EmailTemplateSelectorNewEditor
        onChange={template => setChosenTemplateId(template)}
        value={chosenTemplate}
        templates={templates}
      />
      <div className="flex justify-center">
        <PrimaryButton
          isFetching={isFetching}
          disabled={!chosenTemplate}
          width="large"
          onClick={e => {
            e.preventDefault()
            submit()
          }}
          type="submit"
        >
          {t('global.confirm')}
        </PrimaryButton>
      </div>
    </Modal>
  )
}

export default EmailTemplateSelectorNewEditorModal
