import { toast } from '@systemeio/ui-shared'

export function copyTextToClipboard(text: string, toastText?: string) {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text)
  } else {
    document.execCommand('copy', true, text)
  }
  if (toastText) {
    toast.success(toastText)
  }
}
