import { Tooltip } from '@systemeio/ui-shared'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import {
  getFilteredAttachmentsList,
  isAttachmentFile,
} from 'modules/profile/mailing-settings/utils/file-utils'
import React from 'react'
import { useAttachmentsPolicy } from 'shared/components/dashboard-mail-editor/hooks/use-attachments-policy'
import { AttachmentFileInterface } from 'shared/components/dashboard-mail-editor/types/email-interface'
import { DisclosureBox } from 'shared/components/disclosure-box'
import { FileSelector } from 'shared/components/file-selector'
import { SectionMessage } from 'shared/components/section-message'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BinIcon from 'shared/icons/bin-icon'
import DonwloadIcon from 'shared/icons/donwload-icon'
import { twJoin } from 'tailwind-merge'
import { DashboardMailEditorCommonProps } from '../types/common'
import { concatAttachments } from '../utils/concat-attachments'

export interface AttachmentsBlockProps
  extends Pick<DashboardMailEditorCommonProps, 'isPreFetching' | 'inModal' | 'disabled'> {
  attachmentsTempState: (AttachmentFileInterface | File)[]
  setAttachmentsTempState: React.Dispatch<React.SetStateAction<(AttachmentFileInterface | File)[]>>
  mailingId?: MailingId
}

const AttachmentsBlock = ({
  disabled,
  isPreFetching,
  attachmentsTempState,
  setAttachmentsTempState,
  inModal,
  mailingId,
}: AttachmentsBlockProps) => {
  const { t } = useLocoTranslation()

  const { data } = useAttachmentsPolicy(mailingId)

  return (
    <DisclosureBox
      defaultOpen
      wrapperClassName={twJoin(inModal && 'border border-gray/30 rounded-lg')}
      title={
        <b className={`text-start text-darkblue text-[17px]`}>{t('dashboard.email.attachments')}</b>
      }
    >
      <div className={`gap-5 flex flex-col`}>
        {data?.willBeSentAsLinks && (
          <SectionMessage
            textClassName={'text-blue'}
            iconClassName={'fill-blue'}
            className={'border-blue bg-blue/10'}
          >
            {t('dashboard.email.attachments_as_links')}
          </SectionMessage>
        )}
        {attachmentsTempState.length > 0 && (
          <div className="flex flex-col gap-4">
            {attachmentsTempState.map(attachment => {
              const key = isAttachmentFile(attachment)
                ? attachment.id
                : `${attachment.name}-${attachment.size}`

              return (
                <div key={key} className="flex flex-row gap-2 break-all">
                  {isAttachmentFile(attachment) ? attachment.file?.name : attachment.name}
                  {isAttachmentFile(attachment) && (
                    <Tooltip
                      className="shrink-0"
                      wrapperClassName={'h-fit w-fit self-center'}
                      mode={'hover'}
                      label={t('global.download')}
                      disabled={isPreFetching}
                    >
                      <a
                        target={'_blank'}
                        rel={'noreferrer'}
                        href={attachment.file.path}
                        className={'group outline-none'}
                      >
                        <DonwloadIcon
                          className={`main-transition-colors h-[16px] w-[16px]
                          ${
                            isPreFetching
                              ? 'cursor-default'
                              : 'group-hover:fill-blue group-focus-visible:fill-blue'
                          }`}
                        />
                      </a>
                    </Tooltip>
                  )}
                  <Tooltip
                    className="shrink-0"
                    wrapperClassName={'h-fit w-fit self-center'}
                    mode={'hover'}
                    label={t('global.delete')}
                    disabled={isPreFetching}
                  >
                    <button
                      disabled={disabled}
                      onClick={() =>
                        !disabled &&
                        !isPreFetching &&
                        setAttachmentsTempState(
                          getFilteredAttachmentsList(attachmentsTempState, attachment),
                        )
                      }
                      className={'group outline-none'}
                    >
                      <BinIcon
                        className={`
                        main-transition-colors h-[16px] w-[16px]
                        ${
                          disabled || isPreFetching
                            ? 'cursor-default'
                            : 'group-hover:fill-danger group-focus-visible:fill-danger '
                        }`}
                      />
                    </button>
                  </Tooltip>
                </div>
              )
            })}
          </div>
        )}
        <FileSelector
          disabled={disabled}
          isPreFetching={isPreFetching}
          label={t('dashboard.email.add_attachments')}
          onSelectFile={file => setAttachmentsTempState(prev => concatAttachments(prev, file))}
        />
      </div>
    </DisclosureBox>
  )
}

export default AttachmentsBlock
