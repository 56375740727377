import { Modal, ModalSizeEnum } from '@systemeio/ui-shared'
import Dmarc, { DMARC_KEY } from 'modules/profile/mailing-settings/components/dmarc'
import DomainVerification from 'modules/profile/mailing-settings/components/domain-verification'
import {
  DomainId,
  MailingSettingsDomainDnsSettingsInterface,
} from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import React from 'react'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { DataWithPaginationInterface } from 'shared/types/pagination-interface'

interface MailingSettingsDnsSettingsTableInterface
  extends DataWithPaginationInterface<MailingSettingsDnsItemInterface> {}

export interface MailingSettingsDnsItemInterface {
  id: number
  key: string
  value: string
}

function DnsSettingsModal({
  dnsSettings,
  opened,
  onClose,
  domainId,
}: {
  dnsSettings: MailingSettingsDomainDnsSettingsInterface
  opened: boolean
  onClose: () => void
  domainId: DomainId
}) {
  const { t } = useLocoTranslation()

  const dnsSettingsItems = Object.values(dnsSettings).map((el, index) => {
    return { ...el, id: index }
  })

  const tableDomainData: MailingSettingsDnsSettingsTableInterface = {
    items: [...dnsSettingsItems, { key: DMARC_KEY, id: 0, value: DMARC_KEY }],
    hasMore: false,
  }

  const mailingDnsSettingsColumns: ColumnDefinitionInterface<MailingSettingsDnsItemInterface>[] = [
    {
      key: 'record-type',
      header: t('dashboard.settings.mailing.dns_settings_modal_dns_record_type_title'),
      widthPercent: 20,
      onRender: dns => (dns.key === DMARC_KEY ? <div>TXT</div> : <div>CNAME</div>),
    },
    {
      key: 'name',
      header: t('global.name'),
      widthPercent: 30,
      onRender: dns => <div>{dns.key}</div>,
    },
    {
      key: 'value',
      header: t('dashboard.settings.mailing.dns_settings_modal_value_title'),
      widthPercent: 50,
      onRender: dns =>
        dns.value === DMARC_KEY ? <Dmarc isReady={opened} /> : <div>{dns.value}</div>,
    },
  ]

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title={t('dashboard.settings.mailing.dns_settings_modal_title')}
        size={ModalSizeEnum.big}
      >
        <form className="flex flex-col gap-6 lg:gap-7">
          <div className="flex flex-row gap-4">
            {t('dashboard.settings.mailing.dns_settings_modal_notice_title')}
          </div>
          <Table actions={{}} data={tableDomainData} columns={mailingDnsSettingsColumns} />
          <DomainVerification domainId={domainId} />
        </form>
      </Modal>
    </>
  )
}

export default DnsSettingsModal
