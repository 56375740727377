import { FormSelect, FormSelectProps, Hint } from '@systemeio/ui-shared'
import { useSetDefaultSenderEmail } from 'shared/components/sender-email-select/hooks/use-set-default-sender-email'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import LinkWithoutPrefetch from '../link-without-prefetch'
import TransLoco from '../trans-loco'

export interface SenderEmailSelectProps extends Omit<FormSelectProps<string>, 'data'> {
  hintLabel?: string
  noHint?: boolean
}

function SenderEmailSelect({
  value,
  onChange,
  hintLabel,
  noHint,
  ...rest
}: SenderEmailSelectProps) {
  const { t } = useLocoTranslation()
  const { senderEmailValidList } = useSetDefaultSenderEmail(value, onChange)

  const senderEmailOptions = senderEmailValidList?.map(({ email }) => ({
    id: email,
    caption: email,
  }))

  return (
    <FormSelect
      data={senderEmailOptions}
      value={value || ''}
      onChange={onChange}
      withoutCloseIcon
      label={
        <div className="flex gap-2">
          {t('dashboard.email.sender_email_address')}

          <Hint
            showHint={!noHint}
            label={
              <div>
                {hintLabel ? (
                  hintLabel
                ) : (
                  <TransLoco
                    t={t}
                    i18nKey={'dashboard.email.add_new_email_hint'}
                    components={{
                      a: (
                        <LinkWithoutPrefetch
                          target="_blank"
                          href={`/profile/mailing-settings#verified-email-addresses`}
                          className={'primary-link'}
                        />
                      ),
                    }}
                  />
                )}
              </div>
            }
          />
        </div>
      }
      {...rest}
    />
  )
}

export default SenderEmailSelect
