import { Hint } from '@systemeio/ui-shared'
import React from 'react'
import { EmailSubstitutesSkeleton } from 'shared/components/dashboard-mail-editor/components/ckeditor-helper'
import { useSubstitutes } from 'shared/components/dashboard-mail-editor/hooks/use-substitutes'
import { DisclosureBox } from 'shared/components/disclosure-box'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twJoin } from 'tailwind-merge'
import { DashboardMailEditorCommonProps } from '../types/common'

export interface SubstituteInterface {
  slug: string
  name: string
}

export interface SubstitutesBlockProps extends Pick<DashboardMailEditorCommonProps, 'inModal'> {
  additionalSubstitutes?: SubstituteInterface[]
}

const AFFILIATE_DASHBOARD_DATA = '{affiliate_dashboard}'

const SubstitutesBlock = ({ inModal, additionalSubstitutes }: SubstitutesBlockProps) => {
  const { t } = useLocoTranslation()

  const { substitutionData, isReady } = useSubstitutes(false, additionalSubstitutes)

  return (
    <DisclosureBox
      buttonAs={'div'}
      buttonClassName={'cursor-pointer'}
      wrapperClassName={twJoin(inModal && 'border border-gray/30 rounded-lg')}
      title={
        <Hint label={t('dashboard.email.available_substitutes.tooltip')}>
          <b className={`text-start text-darkblue text-[17px]`}>
            {t('dashboard.email.available_substitutes.title')}
          </b>
        </Hint>
      }
    >
      {isReady ? (
        <ul className={'flex flex-col gap-3'}>
          {substitutionData
            .filter(el => el.data !== AFFILIATE_DASHBOARD_DATA)
            .map(el => (
              <li key={el.data} className={'flex gap-1 flex-wrap'}>
                <span className={'text-danger whitespace-nowrap'}>{el.data}</span>-
                <span>{el.label}</span>
              </li>
            ))}
          <li className={'flex gap-1 flex-wrap'}>
            <span className={'text-danger whitespace-nowrap'}>{AFFILIATE_DASHBOARD_DATA}</span>-
            <Hint
              label={
                <TransLoco
                  t={t}
                  i18nKey={'dashboard.email.clickable_url_description'}
                  components={{
                    a: <span className={'pl-3 block'} />,
                    b: <b />,
                  }}
                />
              }
            >
              {' '}
              {t('dashboard.email.contact_affiliate_url')}
            </Hint>
          </li>
        </ul>
      ) : (
        <EmailSubstitutesSkeleton />
      )}
    </DisclosureBox>
  )
}

export default SubstitutesBlock
