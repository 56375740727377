import { FormSelectIdType, FormSelectInline, FormSelectInlineProps } from '@systemeio/ui-shared'
import { twMerge } from 'tailwind-merge'

export function FormSelectInlineBordered<T extends FormSelectIdType>(
  props: FormSelectInlineProps<T>,
) {
  const { className, captionClassName, arrowButtonClassName, ...rest } = props
  return (
    <FormSelectInline
      className={twMerge('border-gray/30 border rounded-lg px-4 py-2 pr-8', className)}
      captionClassName={twMerge('no-underline', captionClassName)}
      arrowButtonClassName={twMerge('h-[38px] right-3', arrowButtonClassName)}
      {...rest}
    />
  )
}
