import { toast } from '@systemeio/ui-shared'
import { Hint } from '@systemeio/ui-shared'
import { Popover } from '@systemeio/ui-shared'
import FormSenderEmailInput from 'modules/email/components/form-sender-email-input'
import SettingsLayout from 'modules/profile/components/settings-layout'
import SettingsSectionHeading from 'modules/profile/components/settings-section-heading'
import { settingsMenuLocoKeys } from 'modules/profile/constants/settings-menu-loco-keys'
import { ContactsCleaningSettings } from 'modules/profile/mailing-settings/components/contacts-cleaning-settings'
import DomainTable from 'modules/profile/mailing-settings/components/domain-table'
import DoubleOptInModal from 'modules/profile/mailing-settings/components/double-opt-in-modal'
import MailingSettingsSkeleton from 'modules/profile/mailing-settings/components/mailing-settings-skeleton'
import SenderEmailAddresses from 'modules/profile/mailing-settings/components/sender-emails-addresses'
import UnsubscribeLink from 'modules/profile/mailing-settings/components/unsubscribe-link'
import { defaultErrors } from 'modules/profile/mailing-settings/constants/default-errors'
import { defaultValues } from 'modules/profile/mailing-settings/constants/default-values'
import { MailingSettingsLoco } from 'modules/profile/mailing-settings/constants/mailing-settings-loco'
import { useMailingSettings } from 'modules/profile/mailing-settings/hooks/use-mailing-settings'
import { useRemovalConditions } from 'modules/profile/mailing-settings/hooks/use-removal-conditions'
import { useRemovalConditionsUpdate } from 'modules/profile/mailing-settings/hooks/use-update-contacts-cleaning'
import { useUpdateMailingSettings } from 'modules/profile/mailing-settings/hooks/use-update-mailing-settings'
import {
  MailingSettingsEditInterface,
  MailingSettingsErrorsInterface,
} from 'modules/profile/mailing-settings/types/mailing-settings-interface'
import { RemovalConditionsInterface } from 'modules/profile/mailing-settings/types/removal-conditions-interface'
import { NextPage } from 'next'
import React, { useEffect, useState } from 'react'
import { Checkbox } from 'shared/components/checkbox'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import FormInput from 'shared/components/form-input'
import FormTextarea from 'shared/components/form-textarea'
import { SectionMessage } from 'shared/components/section-message'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useScrollToElement from 'shared/hooks/use-scroll-to-element'
import useUser from 'shared/hooks/use-user'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'

const MailingSettings: NextPage = () => {
  const { t } = useLocoTranslation()
  const { user, isWsAssistant } = useUser()

  const { mutate: mutateOptions } = useEmailOptions()

  const {
    removalConditions,
    mutate: mutateRemovalConditions,
    canManageRemovalConditions,
  } = useRemovalConditions()
  const { removalConditionsUpdate } = useRemovalConditionsUpdate()

  const [tempState, setTempState] = useState<MailingSettingsEditInterface>(defaultValues)
  const [tempStateRemovalConditions, setTempStateRemovalConditions] = useState<
    RemovalConditionsInterface[]
  >([])

  const [isOwnSendgrid, setIsOwnSendgrid] = useState(false)
  const [showEmailWarning, setShowEmailWarning] = useState(false)

  const [errors, setErrors] = useState<MailingSettingsErrorsInterface>(defaultErrors)

  const [isFetching, setIsFetching] = useState(false)

  const { mailingData, mutate } = useMailingSettings()
  const { fetcher: updateMailingSettings } = useUpdateMailingSettings()

  const onSubmit = async () => {
    try {
      setErrors(defaultErrors)
      setIsFetching(true)
      if (isOwnSendgrid && !tempState.sendgridApiKey) {
        setErrors(prev => ({
          ...prev,
          sendgridApiKey: t('dashboard.validation.empty_error.title'),
        }))
        setIsFetching(false)
        return
      }
      const responseData = await updateMailingSettings(tempState, errors, setErrors)
      if (canManageRemovalConditions) {
        const newDataRemovalConditions = tempStateRemovalConditions.map(item => ({
          active: item.active,
          type: item.type,
          action: item.action,
          dateRange: item.dateRange,
        }))
        const responseUpdateData = await removalConditionsUpdate({
          listCleanings: newDataRemovalConditions,
        })
        await mutateRemovalConditions(responseUpdateData, false)
      }
      await mutate(responseData, false)
      await mutateOptions()
      setIsFetching(false)
      toast.success(t('global.changes_saved'))
      setErrors(defaultErrors)
    } catch (e) {
      setIsFetching(false)
    }
  }
  useEffect(() => {
    if (mailingData) {
      setTempState(prev => ({
        ...prev,
        sendgridApiKey: mailingData.sendgridApiKey,
        affiliateEmailLink: mailingData.affiliateEmailLink,
        senderEmailAddress: mailingData.senderEmailAddress,
        senderEmailName: mailingData.senderEmailName,
        testEmailAddress: mailingData.testEmailAddress,
        sendTestEmailAfterNewsletterEditing: mailingData.sendTestEmailAfterNewsletterEditing,
        emailMessageFooter: mailingData.emailMessageFooter,
        unsubscriptionLinkText: mailingData.unsubscriptionLinkText,
        displayUnsubscribeFromCampaignLink: mailingData.displayUnsubscribeFromCampaignLink,
      }))
      if (mailingData.sendgridApiKey) {
        setIsOwnSendgrid(true)
      }
    }
    if (removalConditions) {
      setTempStateRemovalConditions(removalConditions)
    }
  }, [mailingData, removalConditions])

  useScrollToElement(globalThis.location?.hash)

  return (
    <SettingsLayout
      titles={[{ caption: t(settingsMenuLocoKeys.mailing), isStatic: true }]}
      onSave={onSubmit}
      disabled={!mailingData}
      isFetching={isFetching}
    >
      {mailingData && user ? (
        <div className="flex flex-col w-full h-fit rounded-md overflow-x-hidden bg-white p-5 gap-7 lg:gap-10 [&>*]:flex-1">
          <div className="flex flex-col gap-7 lg:gap-10">
            <div className="flex flex-col gap-4">
              <SettingsSectionHeading>
                {t('dashboard.settings.mailing.section_heading.send_grid')}
              </SettingsSectionHeading>
              <Checkbox
                value={isOwnSendgrid}
                label={
                  <>
                    <div
                      className={`flex gap-3 items-center ${
                        tempState.sendgridApiKey &&
                        '[&>svg]:fill-blue [&>svg]:main-transition-colors'
                      }`}
                    >
                      {t('dashboard.settings.mailing.sengrid_api_key_title')}
                      <Popover
                        label={
                          <div
                            className="[&>a]:text-blue"
                            dangerouslySetInnerHTML={{
                              __html: t('dashboard.settings.mailing.sengrid_api_key_popover_title'),
                            }}
                          />
                        }
                        popoverClassName={'w-[200px] sm:w-[350px]'}
                      >
                        {show => (
                          <QuestionMarkIcon
                            className={`${
                              show ? 'fill-blue' : 'fill-gray-100'
                            } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                          />
                        )}
                      </Popover>
                    </div>
                  </>
                }
                onChange={() => {
                  if (isOwnSendgrid) {
                    setTempState(prev => ({
                      ...prev,
                      sendgridApiKey: null,
                    }))
                  }
                  setIsOwnSendgrid(prev => !prev)
                }}
              />
              {isOwnSendgrid && (
                <FormInput
                  value={tempState.sendgridApiKey || ''}
                  onChange={e => {
                    setErrors(prev => ({
                      ...prev,
                      sendgridApiKey: '',
                    }))
                    setTempState(prev => ({
                      ...prev,
                      sendgridApiKey: e.target.value,
                    }))
                  }}
                  error={errors.sendgridApiKey}
                />
              )}
            </div>
            {!isOwnSendgrid && (
              <div>
                <SettingsSectionHeading className="mb-4">
                  {t('dashboard.settings.mailing.domains.table_title')}
                </SettingsSectionHeading>
                <DomainTable />
              </div>
            )}
            <div id="verified-email-addresses">
              <SettingsSectionHeading className="mb-4 flex items-center">
                {t('dashboard.settings.mailing.sender_email_addresses.table_title')}
                <Popover
                  label={t('dashboard.settings.mailing.sender_email_address_popover_title')}
                  popoverClassName={'w-[200px] sm:w-[350px]'}
                >
                  {show => (
                    <QuestionMarkIcon
                      className={`ml-1.5 ${
                        show ? 'fill-blue' : 'fill-gray-100'
                      } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                    />
                  )}
                </Popover>
              </SettingsSectionHeading>
              {showEmailWarning && (
                <SectionMessage className="mb-4">
                  {t('dashboard.email.new_google_policy_warning_message')}
                </SectionMessage>
              )}
              <SenderEmailAddresses setShowEmailWarning={setShowEmailWarning} />
            </div>
            <div>
              <SettingsSectionHeading className="mb-4">
                {t('dashboard.settings.mailing.section_heading.sender')}
              </SettingsSectionHeading>
              <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 gap-7 lg:gap-10">
                <FormSenderEmailInput
                  labelClassName={'text-darkblue'}
                  label={
                    <div className={'flex gap-1.5 items-center'}>
                      {t('dashboard.settings.mailing.sender_email_address_title')}
                      <Popover
                        label={t('dashboard.settings.mailing.sender_email_address_popover_title')}
                        popoverClassName={'w-[300px] sm:w-[350px]'}
                      >
                        {show => (
                          <QuestionMarkIcon
                            className={`${
                              show ? 'fill-blue' : 'fill-gray-100'
                            } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                          />
                        )}
                      </Popover>
                    </div>
                  }
                  required
                  value={tempState.senderEmailAddress || ''}
                  onChange={e =>
                    setTempState(prev => ({
                      ...prev,
                      senderEmailAddress: e.target.value,
                    }))
                  }
                  error={errors.senderEmailAddress}
                  disabled={isWsAssistant}
                />
                <FormInput
                  placeholder={t('dashboard.settings.mailing.sender_name_title')}
                  labelClassName={'text-darkblue'}
                  label={
                    <div className={'flex gap-1.5 items-center'}>
                      {t('dashboard.settings.mailing.sender_name_title')}
                      <Popover
                        label={t('dashboard.settings.mailing.sender_name_popover_title')}
                        popoverClassName={'w-[200px] sm:w-[350px]'}
                      >
                        {show => (
                          <QuestionMarkIcon
                            className={`${
                              show ? 'fill-blue' : 'fill-gray-100'
                            } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                          />
                        )}
                      </Popover>
                    </div>
                  }
                  value={tempState.senderEmailName || ''}
                  onChange={e =>
                    setTempState(prev => ({
                      ...prev,
                      senderEmailName: e.target.value,
                    }))
                  }
                  error={errors.senderEmailName}
                />
              </div>
            </div>
            <div className={'flex flex-col gap-4'}>
              <SettingsSectionHeading>
                {t('dashboard.settings.mailing.section_heading.testing')}
              </SettingsSectionHeading>
              <FormSenderEmailInput
                labelClassName={'text-darkblue'}
                label={
                  <div className={'flex gap-1.5 items-center'}>
                    {t('dashboard.settings.mailing.test_email_address_title')}
                    <Popover
                      label={t('dashboard.settings.mailing.test_email_address_popover_title')}
                      popoverClassName={'w-[200px] sm:w-[350px]'}
                    >
                      {show => (
                        <QuestionMarkIcon
                          className={`${
                            show ? 'fill-blue' : 'fill-gray-100'
                          } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                        />
                      )}
                    </Popover>
                  </div>
                }
                required
                value={tempState.testEmailAddress || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    testEmailAddress: e.target.value,
                  }))
                }
                error={errors.testEmailAddress}
              />
              <Checkbox
                value={tempState.sendTestEmailAfterNewsletterEditing}
                label={
                  <>
                    <div
                      className={`flex gap-3 items-center ${
                        tempState.sendTestEmailAfterNewsletterEditing &&
                        '[&>svg]:fill-blue [&>svg]:main-transition-colors'
                      }`}
                    >
                      {t(
                        'dashboard.settings.mailing.send_test_email_after_newsletter_editing_title',
                      )}
                      <Popover
                        label={t(
                          'dashboard.settings.mailing.send_test_email_after_newsletter_editing_popover_title',
                        )}
                        popoverClassName={'w-[200px] sm:w-[350px]'}
                      >
                        {show => (
                          <QuestionMarkIcon
                            className={`${
                              show ? 'fill-blue' : 'fill-gray-100'
                            } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                          />
                        )}
                      </Popover>
                    </div>
                  </>
                }
                onChange={() =>
                  setTempState(prev => {
                    return {
                      ...prev,
                      sendTestEmailAfterNewsletterEditing:
                        !prev.sendTestEmailAfterNewsletterEditing,
                    }
                  })
                }
              />
            </div>
            <div>
              <SettingsSectionHeading className={'mb-4'}>
                <div className="flex items-center">
                  {t('dashboard.settings.mailing.double_opt_in_title')}
                  <Popover
                    label={t('dashboard.settings.mailing.double_opt_in_popover_title')}
                    popoverClassName={'w-[200px] sm:w-[350px]'}
                  >
                    {show => (
                      <QuestionMarkIcon
                        className={`ml-1.5 ${
                          show ? 'fill-blue' : 'fill-gray-100'
                        } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                      />
                    )}
                  </Popover>
                </div>
              </SettingsSectionHeading>
              <div className="w-full">
                <DoubleOptInModal />
              </div>
            </div>

            {removalConditions && canManageRemovalConditions && (
              <div className={'flex gap-4 flex-col'}>
                <SettingsSectionHeading>
                  <div className={'flex gap-1.5 items-center mb-1'}>
                    {t('dashboard.settings.mailing.contacts_cleaning_title')}
                    <Popover
                      label={t('dashboard.settings.mailing.contacts_cleaning_popover_text')}
                      popoverClassName={'w-[200px] sm:w-[350px]'}
                    >
                      {show => (
                        <QuestionMarkIcon
                          className={`${
                            show ? 'fill-blue' : 'fill-gray-100'
                          } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                        />
                      )}
                    </Popover>
                  </div>
                </SettingsSectionHeading>
                <div className={'flex gap-4 flex-col'}>
                  {tempStateRemovalConditions.map((item, index) => (
                    <ContactsCleaningSettings
                      key={`${item.type}-${index}`}
                      removalConditions={item}
                      setTempStateRemovalConditions={setTempStateRemovalConditions}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className={'flex gap-4 flex-col'}>
              <SettingsSectionHeading>
                {t('dashboard.settings.mailing.section_heading.email_footer')}
              </SettingsSectionHeading>
              <FormTextarea
                value={tempState.emailMessageFooter || ''}
                label={
                  <>
                    <div
                      className={`flex gap-3 items-center ${
                        tempState.sendTestEmailAfterNewsletterEditing &&
                        '[&>svg]:fill-blue [&>svg]:main-transition-colors'
                      }`}
                    >
                      {t('dashboard.settings.mailing.email_message_footer_title')}
                      <Popover
                        label={t('dashboard.settings.mailing.email_message_footer_popover_title')}
                        popoverClassName={'w-[200px] sm:w-[350px]'}
                      >
                        {show => (
                          <QuestionMarkIcon
                            className={`${
                              show ? 'fill-blue' : 'fill-gray-100'
                            } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                          />
                        )}
                      </Popover>
                    </div>
                  </>
                }
                className={'min-h-[126px]'}
                error={errors.emailMessageFooter}
                onChange={e =>
                  setTempState(prev => {
                    return {
                      ...prev,
                      emailMessageFooter: e.target.value,
                    }
                  })
                }
              />
              <Checkbox
                value={tempState.affiliateEmailLink}
                label={t('dashboard.settings.mailing.affiliate_email_link_title')}
                onChange={() =>
                  setTempState(prev => {
                    return {
                      ...prev,
                      affiliateEmailLink: !prev.affiliateEmailLink,
                    }
                  })
                }
                disabled={!mailingData.canEditShowEmailAffiliateLink}
              />

              <Checkbox
                value={tempState.displayUnsubscribeFromCampaignLink}
                label={
                  <Hint
                    label={t(
                      'dashboard.settings.mailing.display_unsubscribe_from_campaign_link_popover_title',
                    )}
                  >
                    {t('dashboard.settings.mailing.display_unsubscribe_from_campaign_link')}
                  </Hint>
                }
                onChange={() =>
                  setTempState(prev => {
                    return {
                      ...prev,
                      displayUnsubscribeFromCampaignLink: !prev.displayUnsubscribeFromCampaignLink,
                    }
                  })
                }
              />
              <UnsubscribeLink
                tempState={tempState}
                setTempState={setTempState}
                locale={user.dashboardLocale}
                errors={errors.unsubscriptionLinkText}
              />
            </div>
            {errors.common && (
              <>
                {errors.common.map((error, index) => (
                  <p className="text-sm text-red px-6" key={index}>
                    {error}
                  </p>
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        <MailingSettingsSkeleton />
      )}
    </SettingsLayout>
  )
}

export const getStaticProps = getBaseServerSideProps(MailingSettingsLoco, 'global.settings')

export default MailingSettings
